export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
  },
  {
    key: 'givenCredit',
    label: 'field.givenCredit',
    tdClass: 'text-right',
  },
  {
    key: 'avalBalance',
    label: 'field.avaliableCredit',
    tdClass: 'text-right text-brand',
  },
  {
    key: 'ipaddress',
    label: 'field.ipAddress',
  },
  {
    key: 'lastActivityAt',
    label: 'field.lastActiveDate',
  },
  {
    key: 'isVendor',
    label: 'field.playerType',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    label: 'field.createDate',
  },
]
